body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#name {
  cursor: grab;
}

#links {
  position: absolute;
  top: 18%;
  left: 60%;
  margin-right: 60px;
}

.link {
  font-family: 'Overpass Mono', monospace;
  font-size: 32px;
  margin: 6px;
}

#rainbow-link {
  line-height: 20px;
  background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);   
  -webkit-background-clip: text;
  color: transparent;
}

#rainbow-underline {
  background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
  width: 217px;
  height: 3px;
  margin-bottom: 12px;
}